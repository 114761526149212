<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>

    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-model="form.nome"
        class="col-12"
        :min="1"
        :max="100"
        :label="$t('modulos.local_estocagem.formulario.nome')"
        obrigatorio
      />
      <input-textarea
        v-model="form.descricao"
        :min="0"
        :max="100"
        class="col-12"
        :label="$t('modulos.local_estocagem.formulario.descricao')"
      />
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        :disabled="!valido"
        :tooltip="tooltipBotaoSalvar"
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import LocalEstocagemService from '@common/services/cadastros/LocalEstocagemService';
import { LocalEstocagemModel } from '@common/models/cadastros/LocalEstocagemModel.js';
import helpers from '@common/utils/helpers';
export default {
  props: ['id'],
  data() {
    return {
      valido: false,
      form: new LocalEstocagemModel({}),
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.local_estocagem.titulos.editar');
      return this.$t('modulos.local_estocagem.titulos.novo');
    },
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      return this.$t('modulos.local_estocagem.validacoes.formulario_invalido');
    },
  },
  watch: {
    form: {
      handler() {
        if (this.$refs.form) this.valido = this.$refs.form.validate();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.id) {
      this.buscar(this.id)
      helpers.redirecionarSemPermissao(this, 'LocalEstocagem', 'Editar')
    }
    helpers.redirecionarSemPermissao(this, 'LocalEstocagem', 'Inserir');


    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      LocalEstocagemService.buscar(this.id)
        .then((res) => {
          this.form = new LocalEstocagemModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.local_estocagem.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      LocalEstocagemService.salvar(this.form)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.local_estocagem.cadastro_sucesso`));
          this.$router.push({ name: 'local-estocagem' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'local-estocagem' });
      });
    },
  },
};
</script>
